<template>
    <TheHeader class="fixed-header"/>
    <div class="contact-page">
        <!-- Conteneur principal pour centrer le contenu verticalement et horizontalement -->
        <div class="contact-info">
            <!-- Titre de la page de contact -->
            <h1 class="contact-title">A venir très prochainement !</h1>
            <div class="back-button-container">
              <a href="#" @click.prevent="goBack" class="flex items-center justify-center gap-4 retour-item" aria-label="Go back">
                <!-- Icône de retour -->
                <div class="icon-container" aria-label="Back icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" viewBox="0 0 96 96">
                    <path d="M39.3756,48.0022l30.47-25.39a6.0035,6.0035,0,0,0-7.6878-9.223L26.1563,43.3906a6.0092,6.0092,0,0,0,0,9.2231L62.1578,82.615a6.0035,6.0035,0,0,0,7.6878-9.2231Z"></path>
                  </svg>
                </div>
                <div class="flex flex-col justify-center">
                  <p class="text-primary text-base font-medium leading-normal line-clamp-1">Retour</p>
                </div>
              </a>
            </div>
            <div class="next-button-container">
              <router-link to="/contact" class="flex items-center justify-center gap-4 next-item" aria-label="Go Contact">
                <!-- Icône de Contact -->
                <div class="icon-container" aria-label="Contact icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                  viewBox="0 0 512 512">
                  <path d="M16.1 260.2c-22.6 12.9-20.5 47.3 3.6 57.3L160 376V479.3c0 18.1 14.6 32.7 32.7 32.7c9.7 0 18.9-4.3 25.1-11.8l62-74.3 123.9 51.6c18.9 7.9 40.8-4.5 43.9-24.7l64-416c1.9-12.1-3.4-24.3-13.5-31.2s-23.3-7.5-34-1.4l-448 256zm52.1 25.5L409.7 90.6 190.1 336l1.2 1L68.2 285.7zM403.3 425.4L236.7 355.9 450.8 116.6 403.3 425.4z" />
                </svg>
                </div>
                <div class="flex flex-col justify-center">
                  <p class="text-primary text-base font-medium leading-normal line-clamp-1">Contact</p>
                </div>
              </router-link>
            </div>
        </div>
    </div>
</template>

<script>
  import TheHeader from '@/components/TheHeader.vue';
  
  export default {
    name: 'ServicesPage',
    methods: {
      goBack() {
        // Méthode pour retourner à la page précédente
        window.history.back();
      }
    },
    data() {
      return {
        // Utilisation de require pour inclure l'image. Assurez-vous que le chemin vers logo2.png est correct.
        logoUrl: require('@/assets/logo2.png')
      };
    },
    components: {
      TheHeader
    }
  }
  </script>
  
  
  <style scoped>
  .contact-page {
    /* Styles pour le conteneur principal */
    display: flex; /* Utilisation de flexbox pour centrer le contenu */
    justify-content: center; /* Centre horizontalement */
    align-items: center; /* Centre verticalement */
    min-height: 100vh; /* Prend toute la hauteur de l'écran */
    position: relative; /* Positionnement relatif pour le bouton retour */
  }
  
  .fixed-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: #fff; /* Ajustez si votre en-tête a une couleur de fond */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optionnel : ajoute une ombre pour une meilleure séparation */
}

  .contact-info {
    /* Styles pour le conteneur d'informations de contact */
    text-align: center; /* Centre le texte à l'intérieur du conteneur */
  }
  
  .contact-title {
    /* Styles pour le titre de la page de contact */
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .contact-container {
    /* Styles pour le conteneur des éléments de contact */
    display: flex; /* Utilisation de flexbox pour les éléments */
    flex-direction: row; /* Alignement en ligne */
    gap: 20px; /* Espacement entre les éléments */
    justify-content: center; /* Centre les éléments horizontalement */
    flex-wrap: wrap; /* Permet l'ajustement sur les petits écrans */
  }
  
  .retour-item {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px #71c8db;
  transition: transform 0.3s, box-shadow 0.3s;
  max-width: 20px;
  flex: 1;
  min-width: 150px;
}

.retour-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px #71c8db;
}

.next-item {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px #71c8db;
  transition: transform 0.3s, box-shadow 0.3s;
  max-width: 200px;
  flex: 1;
  min-width: 150px;
}

.next-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px #71c8db;
}

.next-button-container {
  position: fixed;
  bottom: 20px; /* Ajustez la distance par rapport au bas de la page */
  right: 20px; /* Ajustez la distance par rapport à la gauche de la page */
  z-index: 1000;
}

  .contact-link {
    /* Styles pour les liens de contact */
    text-decoration: none; /* Retire la décoration de lien par défaut */
    display: flex; /* Utilisation de flexbox pour les éléments */
    align-items: center; /* Centre verticalement les éléments */
    gap: 10px; /* Espacement entre l'icône et le texte */
  }
  
  .icon-container {
    /* Styles pour le conteneur des icônes */
    display: flex; /* Utilisation de flexbox pour centrer l'icône */
    align-items: center; /* Centre verticalement */
    justify-content: center; /* Centre horizontalement */
    background-color: #71c8db; /* Couleur de fond de l'icône */
    ; /* Couleur de fond de l'icône */
    width: 45px; /* Largeur de l'icône */
    height: 45px; /* Hauteur de l'icône */
    border-radius: 50%; /* Forme ronde */
  }
  
  .text-primary {
    /* Styles pour le texte principal */
    color: #111418; /* Couleur du texte principal */
    font-size: 1.2em; /* Taille de la police */
  }
  
  .text-secondary {
    /* Styles pour le texte secondaire */
    color: #637588; /* Couleur du texte secondaire */
    font-size: 1em; /* Taille de la police */
  }
  
  .back-button-container {
    /* Styles pour le conteneur du bouton de retour */
    position: fixed; /* Position absolue par rapport à .contact-page */
    bottom: 20px; /* Positionné à 20px du bas de la page */
    left: 20px; /* Positionné à 20px de la gauche de la page */
  }
  </style>
