<template>
    <!-- HEADER -->
    <header class="fixed top-0 left-0 w-full bg-white shadow">
      <div class="container mx-auto flex justify-center items-center h-16 px-4">
        <!-- Utilisation de la propriété logoUrl pour s'assurer que Webpack traite correctement le fichier image -->
        <img :src="logoUrl" alt="Logo" class="h-12 md:h-16">
      </div>
    </header>
    
  </template>
  
  <script>
  export default {
    data() {
      return {
        // Utilisation de require pour inclure l'image. Assurez-vous que le chemin vers logo.png est correct.
        logoUrl: require('@/assets/logo2.png')
      }
    }
  }
  </script>
  
  <style>
  /* Styles supplémentaires si nécessaire */
  </style>