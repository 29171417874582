// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

// Import TailwindCSS
import './assets/tailwind.css'

// Créer l'application Vue
const app = createApp(App);

// Désactiver les avertissements de production (optionnel)
app.config.warnHandler = () => {}

// Utiliser le routeur et monter l'application
app.use(router);
app.mount('#app');