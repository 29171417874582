<!-- src/components/HomePage.vue -->
<template>
  <TheHeader class="fixed-header" />
  <div id="app" class="relative">
    <main>
    
      <!-- Photo -->
    <div class="relative flex min-h-[350px] flex-col gap-6 bg-cover bg-center bg-no-repeat @[350px]:gap-8 @[350px]:rounded-xl items-start justify-end px-4 pb-10 @[350px]:px-10"
      :style="{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.4) 100%), url(${fondUrl})` }">
  
      <!--Texte Photo-->
      <div class="absolute bottom-10 left-4 @[480px]:left-10 flex flex-col gap-2 text-">
        <h1
          class="text-white text-4xl font-black leading-tight tracking-[-0.033em] @[350px]:text-5xl @[480px]:font-black @[350px]:leading-tight @[350px]:tracking-[-0.033em] shadow-text">
          Conseils Informatiques pour particuliers et PME
        </h1>
        <h2 class="text-white text-base sm:text-lg md:text-xl lg:text-2xl font-normal leading-normal shadow-text">
          Un accompagnement personnalisé pour tous vos projets
        </h2>
      </div>
    </div>
      
      <!-- Manouch Espace -->
      <div class="h-6 bg-white"></div>
      
      <!-- Création Conatainer Colonne -->
      <div class="flex flex-col gap-6 px-4 py-0 @container">
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-5 p-0">

          <!-- Colonnes -->
          <div class="flex flex-1 gap-3 rounded-lg border border-[#71c8db] bg-white p-4 flex-col">
            <div class="text-[#111418] flex items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#71c8db"
                viewBox="0 0 256 256">
                <path
                  d="M208,40H48A16,16,0,0,0,32,56v58.78c0,89.61,75.82,119.34,91,124.39a15.53,15.53,0,0,0,10,0c15.2-5.05,91-34.78,91-124.39V56A16,16,0,0,0,208,40Zm0,74.79c0,78.42-66.35,104.62-80,109.18-13.53-4.51-80-30.69-80-109.18V56H208ZM82.34,141.66a8,8,0,0,1,11.32-11.32L112,148.68l50.34-50.34a8,8,0,0,1,11.32,11.32l-56,56a8,8,0,0,1-11.32,0Z">
                </path>
              </svg>
            </div>
            <div class="flex flex-col gap-1">
              <h2 class="text-[#111418] text-base font-bold leading-tight text-center custom-H2">Sécurité de vos données
              </h2>
              <p class="text-[#637588] text-sm font-normal leading-normal custom-P mt-2">
                Un interlocuteur unique qui respecte la confidentialité de vos données numériques et les recommandations
                de l'ANSSI les concernant.
              </p>
            </div>
          </div>
          <div class="flex flex-1 gap-3 rounded-lg border border-[#71c8db] bg-white p-4 flex-col">
            <div class="text-[#111418] flex items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#71c8db"
                viewBox="0 0 256 256">
                <path
                  d="M128,64a40,40,0,1,0,40,40A40,40,0,0,0,128,64Zm0,64a24,24,0,1,1,24-24A24,24,0,0,1,128,128Zm0-112a88.1,88.1,0,0,0-88,88c0,31.4,14.51,64.68,42,96.25a254.19,254.19,0,0,0,41.45,38.3,8,8,0,0,0,9.18,0A254.19,254.19,0,0,0,174,200.25c27.45-31.57,42-64.85,42-96.25A88.1,88.1,0,0,0,128,16Zm0,206c-16.53-13-72-60.75-72-118a72,72,0,0,1,144,0C200,161.23,144.53,209,128,222Z">
                </path>
              </svg>
            </div>
            <div class="flex flex-col gap-1">
              <h2 class="text-[#111418] text-base font-bold leading-tight text-center custom-H2">Local et
                éco-responsable</h2>
              <p class="text-[#637588] text-sm font-normal leading-normal custom-P mt-2">
                Installé à Saint Max (54130), je travaille essentiellement pour des clients de la région. <br>
                Les solutions soumises seront toujours adaptées et dimensionnées afin d'éviter coûts inutiles et
                empreinte carbonne démesurée.
              </p>
            </div>
          </div>
          <div class="flex flex-1 gap-3 rounded-lg border border-[#71c8db] bg-white p-4 flex-col">
            <div class="text-[#111418] flex items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#71c8db"
                viewBox="0 0 256 256">
                <path
                  d="M226.76,69a8,8,0,0,0-12.84-2.88l-40.3,37.19-17.23-3.7-3.7-17.23,37.19-40.3A8,8,0,0,0,187,29.24,72,72,0,0,0,88,96,72.34,72.34,0,0,0,94,124.94L33.79,177c-.15.12-.29.26-.43.39a32,32,0,0,0,45.26,45.26c.13-.13.27-.28.39-.42L131.06,162A72,72,0,0,0,232,96,71.56,71.56,0,0,0,226.76,69ZM160,152a56.14,56.14,0,0,1-27.07-7,8,8,0,0,0-9.92,1.77L67.11,211.51a16,16,0,0,1-22.62-22.62L109.18,133a8,8,0,0,0,1.77-9.93,56,56,0,0,1,58.36-82.31l-31.2,33.81a8,8,0,0,0-1.94,7.1L141.83,108a8,8,0,0,0,6.14,6.14l26.35,5.66a8,8,0,0,0,7.1-1.94l33.81-31.2A56.06,56.06,0,0,1,160,152Z">
                </path>
              </svg>
            </div>
            <div class="flex flex-col gap-1">
              <h2 class="text-[#111418] text-base font-bold leading-tight text-center custom-H2">Temps de réponse rapide
              </h2>
              <p class="text-[#637588] text-sm font-normal leading-normal custom-P mt-2">
                Je sais à quel point il est important d'avoir une intervention rapide lorsque vous rencontrez un
                incident.<br>
                Des solutions temporaires peuvent vous être proposées afin de rétablir rapidement vos services.
              </p>
            </div>
          </div>
          <div class="flex flex-1 gap-3 rounded-lg border border-[#71c8db] bg-white p-4 flex-col">
            <div class="text-[#111418] flex items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#71c8db"
                viewBox="0 0 256 256">
                <path
                  d="M117.25,157.92a60,60,0,1,0-66.5,0A95.83,95.83,0,0,0,3.53,195.63a8,8,0,1,0,13.4,8.74,80,80,0,0,1,134.14,0,8,8,0,0,0,13.4-8.74A95.83,95.83,0,0,0,117.25,157.92ZM40,108a44,44,0,1,1,44,44A44.05,44.05,0,0,1,40,108Zm210.14,98.7a8,8,0,0,1-11.07-2.33A79.83,79.83,0,0,0,172,168a8,8,0,0,1,0-16,44,44,0,1,0-16.34-84.87,8,8,0,1,1-5.94-14.85,60,60,0,0,1,55.53,105.64,95.83,95.83,0,0,1,47.22,37.71A8,8,0,0,1,250.14,206.7Z">
                </path>
              </svg>
            </div>
            <div class="flex flex-col gap-1">
              <h2 class="text-[#111418] text-base font-bold leading-tight text-center custom-H2">Support professionnel
              </h2>
              <p class="text-[#637588] text-sm font-normal leading-normal custom-P mt-2">
                Mes expériences de technicien support VIP et d'administrateur système et réseau me permettront de vous
                accompagner afin que cet outil indispensable ne soit plus une contrainte.
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Manouch Espace -->
      <div class="h-3 bg-white"></div>
      
      <!-- Boutons de Naviguation -->
      <div class="home-page">
        <div class="button-container">
          <!-- Bouton CV -->
          <div class="flex justify-center items-center gap-4 contact-item">
            <router-link to="/cv" @click.prevent="scrollToTop" class="flex items-center justify-center gap-4 contact-link" aria-label="View CV">
              <div class="icon-container" aria-label="CV icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                  viewBox="0 0 576 512">
                  <path
                    d="M512 80c8.8 0 16 7.2 16 16V416c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V96c0-8.8 7.2-16 16-16H512zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM208 256a64 64 0 1 0 0-128 64 64 0 1 0 0 128zm-32 32c-44.2 0-80 35.8-80 80c0 8.8 7.2 16 16 16H304c8.8 0 16-7.2 16-16c0-44.2-35.8-80-80-80H176zM376 144c-13.3 0-24 10.7-24 24s10.7 24 24 24h80c13.3 0 24-10.7 24-24s-10.7-24-24-24H376zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24h80c13.3 0 24-10.7 24-24s-10.7-24-24-24H376z" />
                </svg>
              </div>
              <div class="flex flex-col justify-center">
                <p class="text-primary text-base font-medium leading-normal line-clamp-1">CV</p>
              </div>
            </router-link>
          </div>
          <!-- Bouton Services -->
          <div class="flex justify-center items-center gap-4 contact-item">
            <router-link to="/services" class="flex items-center justify-center gap-4 contact-link" aria-label="View Services">
              <div class="icon-container" aria-label="Services icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                  viewBox="0 0 512 512">
                  <path
                    d="M176 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64c-35.3 0-64 28.7-64 64H24c-13.3 0-24 10.7-24 24s10.7 24 24 24H64v56H24c-13.3 0-24 10.7-24 24s10.7 24 24 24H64v56H24c-13.3 0-24 10.7-24 24s10.7 24 24 24H64c0 35.3 28.7 64 64 64v40c0 13.3 10.7 24 24 24s24-10.7 24-24V448h56v40c0 13.3 10.7 24 24 24s24-10.7 24-24V448h56v40c0 13.3 10.7 24 24 24s24-10.7 24-24V448c35.3 0 64-28.7 64-64h40c13.3 0 24-10.7 24-24s-10.7-24-24-24H448V280h40c13.3 0 24-10.7 24-24s-10.7-24-24-24H448V176h40c13.3 0 24-10.7 24-24s-10.7-24-24-24H448c0-35.3-28.7-64-64-64V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H280V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H176V24zM160 128H352c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H160c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32zm192 32H160V352H352V160z" />
                </svg>
              </div>
              <div class="flex flex-col justify-center">
                <p class="text-primary text-base font-medium leading-normal line-clamp-1">Services</p>
              </div>
            </router-link>
          </div>
          <!-- Bouton Contact -->
          <div class="flex justify-center items-center gap-4 contact-item">
            <router-link to="/contact" class="flex items-center justify-center gap-4 contact-link" aria-label="Contact Me">
              <div class="icon-container" aria-label="Contact icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                  viewBox="0 0 512 512">
                  <path
                    d="M16.1 260.2c-22.6 12.9-20.5 47.3 3.6 57.3L160 376V479.3c0 18.1 14.6 32.7 32.7 32.7c9.7 0 18.9-4.3 25.1-11.8l62-74.3 123.9 51.6c18.9 7.9 40.8-4.5 43.9-24.7l64-416c1.9-12.1-3.4-24.3-13.5-31.2s-23.3-7.5-34-1.4l-448 256zm52.1 25.5L409.7 90.6 190.1 336l1.2 1L68.2 285.7zM403.3 425.4L236.7 355.9 450.8 116.6 403.3 425.4z" />
                </svg>
              </div>
              <div class="flex flex-col justify-center">
                <p class="text-primary text-base font-medium leading-normal line-clamp-1;">Contact</p>
              </div>
            </router-link>
          </div>
        </div>
        <!-- Manouch Espace -->
        <div class="h-3 bg-white"></div>
      </div>
    </main>
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader.vue';

export default {
  name: 'HomePage',
  components: {
    TheHeader
  },
  data() {
    return {
      // Utilisation de require pour inclure l'image. Assurez-vous que le chemin vers logo.png est correct.
      fondUrl: require('@/assets/fond_main.webp')
    }
  },
  methods: {
    goBack() {
      window.history.back();
    },
    handleGoBack() {
      this.goBack();
      window.scrollTo(0, 0); // Fait défiler la page vers le haut
    },
    scrollToTop() {
      window.scrollTo(0, 0); // Fait défiler la page vers le haut
      this.$router.push('/cv'); // Redirige vers la page CV
    }
  }
}
</script>
<style scoped>
.home-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 15vh;
  flex-direction: column;
}

.fixed-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: #fff; /* Ajustez si votre en-tête a une couleur de fond */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optionnel : ajoute une ombre pour une meilleure séparation */
}

.button-container {
  display: flex;
  flex-direction: row;
  /* Changer la direction en ligne */
  gap: 30px;
  flex-wrap: wrap;
  align-items: center; /* Centre verticalement */
  justify-content: center; /* Centre horizontalement */
  /* Permettre l'ajustement sur les petits écrans */
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: rgb(255, 255, 255);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px #71c8db;
  transition: transform 0.3s, box-shadow 0.3s;
  max-width: 250px;
  flex: 1;
  min-width: 200px;
  min-height: 70px;
  max-height: 70px;
}

.contact-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px #71c8db;
}

.contact-link {
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 10px;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #71c8db;
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.text-primary {
  color: #111418;
  font-size: 1.5em;
}

.text-secondary {
  color: #637588;
  font-size: 1em;
}

.custom-H2 {
  font-size: 1.2rem;
}

.custom-P {
  font-size: 1.1rem;
  text-align: center;
  margin-bottom: 1rem
}
.header-overlay {
  position: relative;
  z-index: 10;
}

#app {
  position: relative;
  z-index: 1;
}
.shadow-text {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
}

</style>