<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  // Aucun besoin de déclarer le routeur ici si vous l'avez déjà configuré dans router/index.js
});
</script>

<style>
/* ... */
</style>