// src/router/index.js
import { createRouter, createWebHashHistory } from 'vue-router';
import HomePage from '../components/HomePage.vue';
import ContactPage from '../components/ContactPage.vue';
import CvPage from '../components/CvPage.vue';
import ServicesPage from '../components/ServicesPage.vue';

const routes = [
    { path: '/', component: HomePage },
    { path: '/contact', component: ContactPage },
    { path: '/cv', component: CvPage },
    { path: '/services', component: ServicesPage }
];

const router = createRouter({
    mode: 'history',
    history: createWebHashHistory(),
    routes,
});

export default router;
