<template>
  <div id="app">
    <TheHeader class="fixed-header" />
    <main class="main-container">
      
      <!-- Conteneur principal pour centrer le contenu verticalement et horizontalement -->
      <div class="cv-info">
        
        <!-- Création Conatainer Colonne -->
        <div class="flex flex-col gap-6 px-4 py-0 @container">
          <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-5 p-0">
            
            <!-- Colonnes Micro-Entrepreneur -->
            <div class="flex flex-1 gap-3 rounded-lg border border-[#71c8db] bg-white p-4 flex-col">
              <div class="text-[#111418] flex items-center justify-center">
              </div>
              <div class="flex flex-col gap-1">
                <img src="https://i.ibb.co/rkJRP7x/Micro-Entrepreneur.jpg" alt="Micro Entrepreneur" class="column-image">
                <!-- Manouch Espace -->
                <div class="h-6 bg-white"></div>
                <h2 class="text-[#111418] text-base font-bold leading-tight text-center custom-H2">
                  2010/2016<br>
                  Micro Entrepreneur<br>
                  Technicien Informatique<br>
                  (Service et achat/vente)
                </h2>
                <p class="text-[#637588] text-sm font-normal leading-normal custom-P">
                  Metz (57000)
                </p>
              </div>
            </div>
            <!-- Colonne Tech Paris -->
            <div class="flex flex-1 gap-3 rounded-lg border border-[#71c8db] bg-white p-4 flex-col">
              <div class="text-[#111418] flex items-center justify-center">
              </div>
              <div class="flex flex-col gap-1">
                <img src="https://i.ibb.co/YRpK1hF/Technicien-Support.jpg" alt="Technicien Support Nomade" class="column-image">
                <!-- Manouch Espace -->
                <div class="h-6 bg-white"></div>
                <h2 class="text-[#111418] text-base font-bold leading-tight text-center custom-H2">
                  2017/2019<br>
                  Technicien support nomade<br>
                </h2>
                <p class="text-[#637588] text-sm font-normal leading-normal custom-P">
                  Issy-les-Moulineaux (92130)<br>
                  Boulogne-Billancourt (92100)<br>
                  La Défense (92400)<br>
                  Paris(75008)<br>
                  Nanterre (92000)
                </p>
              </div>
            </div>
            <!-- Colonne UCPA -->
            <div class="flex flex-1 gap-3 rounded-lg border border-[#71c8db] bg-white p-4 flex-col">
              <div class="text-[#111418] flex items-center justify-center">
            </div>
            <div class="flex flex-col gap-1">
              <img src="https://i.ibb.co/qkhKCyr/UCPA.jpg" alt="UCPA" class="column-image">
              <!-- Manouch Espace -->
              <div class="h-6 bg-white"></div>
                <h2 class="text-[#111418] text-base font-bold leading-tight text-center custom-H2">
                  2019/2020<br>
                  Administrateur Sytèmes et réseaux<br>
                </h2>
                <p class="text-[#637588] text-sm font-normal leading-normal custom-P">
                  Paris (75014)
                </p>
              </div>
            </div>
            <!-- Colonne Arketeam -->
            <div class="flex flex-1 gap-3 rounded-lg border border-[#71c8db] bg-white p-4 flex-col">
              <div class="text-[#111418] flex items-center justify-center">
            </div>
            <div class="flex flex-col gap-1">
              <img src="https://i.ibb.co/G3H8gDn/Arketeam.jpg" alt="Arketeam" class="column-image">
              <!-- Manouch Espace -->
              <div class="h-6 bg-white"></div>
                <h2 class="text-[#111418] text-base font-bold leading-tight text-center custom-H2">
                  2021/2023<br>
                  Administrateur Sytèmes et réseaux<br>
                </h2>
                <p class="text-[#637588] text-sm font-normal leading-normal custom-P">
                  Vandœuvre-lès-Nancy (54500)
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="button-container">
          <div class="back-button-container">
            <a href="#" @click.prevent="goBack" class="flex items-center justify-center gap-4 retour-item" aria-label="Go back">
            <!-- Icône de retour -->
              <div class="icon-container" aria-label="Back icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" viewBox="0 0 96 96">
                  <path d="M39.3756,48.0022l30.47-25.39a6.0035,6.0035,0,0,0-7.6878-9.223L26.1563,43.3906a6.0092,6.0092,0,0,0,0,9.2231L62.1578,82.615a6.0035,6.0035,0,0,0,7.6878-9.2231Z"></path>
                </svg>
              </div>
              <div class="flex flex-col justify-center">
                <p class="text-primary text-base font-medium leading-normal line-clamp-1">Retour</p>
              </div>
            </a>
          </div>
          <div class="next-button-container">
            <router-link to="/services" class="flex items-center justify-center gap-4 next-item" aria-label="Go Services">
              <!-- Icône de Services -->
              <div class="icon-container" aria-label="Services icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 512 512">
                  <path d="M176 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64c-35.3 0-64 28.7-64 64H24c-13.3 0-24 10.7-24 24s10.7 24 24 24H64v56H24c-13.3 0-24 10.7-24 24s10.7 24 24 24H64v56H24c-13.3 0-24 10.7-24 24s10.7 24 24 24H64c0 35.3 28.7 64 64 64v40c0 13.3 10.7 24 24 24s24-10.7 24-24V448h56v40c0 13.3 10.7 24 24 24s24-10.7 24-24V448h56v40c0 13.3 10.7 24 24 24s24-10.7 24-24V448c35.3 0 64-28.7 64-64h40c13.3 0 24-10.7 24-24s-10.7-24-24-24H448V280h40c13.3 0 24-10.7 24-24s-10.7-24-24-24H448V176h40c13.3 0 24-10.7 24-24s-10.7-24-24-24H448c0-35.3-28.7-64-64-64V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H280V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H176V24zM160 128H352c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H160c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32zm192 32H160V352H352V160z" />
                </svg>
              </div>
              <div class="flex flex-col justify-center">
                <p class="text-primary text-base font-medium leading-normal line-clamp-1">Services</p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader.vue';

export default {
  name: 'HomePage',
  components: {
    TheHeader
  },
  methods: {
    goBack() {
      // Méthode pour retourner à la page précédente
      window.history.back();
    }
  },
}
</script>


<style scoped>
#app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.fixed-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: #fff; /* Ajustez si votre en-tête a une couleur de fond */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optionnel : ajoute une ombre pour une meilleure séparation */
}

.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin-top: 1%; /* Ajustez ceci si la hauteur de votre en-tête est différente */
  padding: 0 10px; /* Réduire les marges latérales */
}

.cv-info {
  width: 100%;
  max-width: 2000px; /* Ajustez selon vos besoins pour réduire l'espace */
  padding: 0 1px; /* Réduire les marges latérales */
}

header {
  width: 100%;
}

.grid {
  width: 100%;
}
.text-primary {
  /* Styles pour le texte principal */
  color: #111418; /* Couleur du texte principal */
  font-size: 1.2em; /* Taille de la police */
}

.button-container {
  display: flex;
  justify-content: space-between; /* Espace entre les deux boutons */
  margin-top: 80px; /* Espace entre les colonnes et les boutons */
  margin-bottom: 20px;
}

.back-button-container {
  display: flex;
  justify-content: left;
  margin-left: 10px
}

.next-button-container {
  margin-top: 0px; /* Espace entre les colonnes et le bouton */
  display: flex;
  justify-content: right;
  margin-right: 10px
}

.retour-item {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px #71c8db;
  transition: transform 0.3s, box-shadow 0.3s;
  max-width: 200px;
  flex: 1;
  min-width: 150px;
}

.retour-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px #71c8db;
}

.next-item {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px #71c8db;
  transition: transform 0.3s, box-shadow 0.3s;
  max-width: 200px;
  flex: 1;
  min-width: 150px;
}

.next-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px #71c8db;
}

.icon-container {
    /* Styles pour le conteneur des icônes */
    display: flex; /* Utilisation de flexbox pour centrer l'icône */
    align-items: center; /* Centre verticalement */
    justify-content: center; /* Centre horizontalement */
    background-color: #71c8db; /* Couleur de fond de l'icône */
    ; /* Couleur de fond de l'icône */
    width: 45px; /* Largeur de l'icône */
    height: 45px; /* Hauteur de l'icône */
    border-radius: 50%; /* Forme ronde */
  }
.custom-H2 {
  font-size: 1.2rem;
}

.custom-P {
  font-size: 1.1rem;
  text-align: center;
  margin-bottom: 1rem
}
</style>

