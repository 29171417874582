<template>
  <TheHeader class="fixed-header"/>
  <div class="contact-page">
    <!-- Conteneur principal pour centrer le contenu verticalement et horizontalement -->
    <div class="contact-info">
      <!-- Titre de la page de contact -->
      <h1 class="contact-title">Pour me contacter :</h1>

      <!-- Conteneur des éléments de contact, centré horizontalement -->
      <div class="flex px-4 py-3 contact-container">
        <!-- Élément de contact pour le téléphone -->
        <div class="flex items-center justify-center gap-4 contact-item">
          <!-- Icône de téléphone -->
          <div class="icon-container" aria-label="Phone icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" viewBox="0 0 256 256">
              <path d="M222.37,158.46l-47.11-21.11-.13-.06a16,16,0,0,0-15.17,1.4,8.12,8.12,0,0,0-.75.56L134.87,160c-15.42-7.49-31.34-23.29-38.83-38.51l20.78-24.71c.2-.25.39-.5.57-.77a16,16,0,0,0,1.32-15.06l0-.12L97.54,33.64a16,16,0,0,0-16.62-9.52A56.26,56.26,0,0,0,32,80c0,79.4,64.6,144,144,144a56.26,56.26,0,0,0,55.88-48.92A16,16,0,0,0,222.37,158.46ZM176,208A128.14,128.14,0,0,1,48,80,40.2,40.2,0,0,1,82.87,40a.61.61,0,0,0,0,.12l21,47L83.2,111.86a6.13,6.13,0,0,0-.57.77,16,16,0,0,0-1,15.7c9.06,18.53,27.73,37.06,46.46,46.11a16,16,0,0,0,15.75-1.14,8.44,8.44,0,0,0,.74-.56L168.89,152l47,21.05h0s.08,0,.11,0A40.21,40.21,0,0,1,176,208Z"></path>
            </svg>
          </div>
          <!-- Texte et détails pour le téléphone -->
          <div class="flex flex-col justify-center">
            <p class="text-primary text-base font-medium leading-normal line-clamp-1">Téléphone</p>
            <p class="text-secondary text-sm font-normal leading-normal line-clamp-2">07 62 30 35 35</p>
          </div>
        </div>

        <!-- Élément de contact pour le mail -->
        <div class="flex items-center justify-center gap-4 contact-item">
          <!-- Lien vers le mail -->
          <a href="mailto:contact@jonathanU.info" class="flex items-center gap-4 contact-link" aria-label="Send an email">
            <!-- Icône de mail -->
            <div class="icon-container" aria-label="Mail icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="M4 4h16c1.1 0 2 .9 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2z"></path>
                <polyline points="22,6 12,13 2,6"></polyline>
              </svg>
            </div>
            <!-- Texte et détails pour le mail -->
            <div class="flex flex-col justify-center">
              <p class="text-primary text-base font-medium leading-normal line-clamp-1">Mail</p>
              <p class="text-secondary text-sm font-normal leading-normal line-clamp-2">contact@JonathanU.info</p>
            </div>
          </a>
        </div>
      </div>

      <!-- Titre de la page de contact -->
      <h1 class="contact-title">Pour réserver :</h1>

      <!-- Widget RDV360 -->
      <div class="rdv360-widget" data-apikey="9bf923b51d01a251a7c49e5ccba4fbc9035b25be" data-apisecret="cfde496d4f1425cab94820ce4c3b5aa34270f053" data-no_stripe="true" id="widget-vuejs"></div>
      <!-- Fin Widget RDV360 -->

      <!-- Bouton de retour -->
      <div class="back-button-container">
        <a href="#" @click.prevent="goBack" class="flex items-center justify-center gap-4 retour-item" aria-label="Go back">
          <!-- Icône de retour -->
          <div class="icon-container" aria-label="Back icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" viewBox="0 0 96 96">
              <path d="M39.3756,48.0022l30.47-25.39a6.0035,6.0035,0,0,0-7.6878-9.223L26.1563,43.3906a6.0092,6.0092,0,0,0,0,9.2231L62.1578,82.615a6.0035,6.0035,0,0,0,7.6878-9.2231Z"></path>
            </svg>
          </div>
          <div class="flex flex-col justify-center">
            <p class="text-primary text-base font-medium leading-normal line-clamp-1">Retour</p>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader.vue';

export default {
  name: 'ContactPage',
  mounted() {
    const script = document.createElement('script');
    script.src = "https://www.rdv360.com/widget/dist/app_vuidget_v3.js";
    script.async = true;
    document.body.appendChild(script);
  },
  components: {
    TheHeader
  },
  methods: {
    goBack() {
      // Méthode pour retourner à la page précédente
      window.history.back();
    }
  },
}
</script>

<style scoped>
.contact-page {
  /* Styles pour le conteneur principal */
  display: flex; /* Utilisation de flexbox pour centrer le contenu */
  justify-content: center; /* Centre horizontalement */
  align-items: center; /* Centre verticalement */
  min-height: 100vh; /* Prend toute la hauteur de l'écran */
  position: relative; /* Positionnement relatif pour le bouton retour */
}

.fixed-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: #fff; /* Ajustez si votre en-tête a une couleur de fond */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optionnel : ajoute une ombre pour une meilleure séparation */
}

.contact-info {
  /* Styles pour le conteneur d'informations de contact */
  text-align: center; /* Centre le texte à l'intérieur du conteneur */
}

.contact-title {
  /* Styles pour le titre de la page de contact */
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 20px;
}

.contact-container {
  /* Styles pour le conteneur des éléments de contact */
  display: flex; /* Utilisation de flexbox pour les éléments */
  flex-direction: row; /* Alignement en ligne */
  gap: 20px; /* Espacement entre les éléments */
  justify-content: center; /* Centre les éléments horizontalement */
  flex-wrap: wrap; /* Permet l'ajustement sur les petits écrans */
}

.contact-item {
  /* Styles pour chaque élément de contact */
  display: flex; /* Utilisation de flexbox pour les éléments */
  align-items: center; /* Centre verticalement les éléments */
  gap: 10px; /* Espacement entre l'icône et le texte */
  background-color: white; /* Couleur de fond */
  padding: 20px; /* Padding interne */
  border-radius: 10px; /* Bords arrondis */
  box-shadow: 0 4px 8px #71c8db; /* Ombre */
  transition: transform 0.3s, box-shadow 0.3s; /* Animation douce */
  max-width: 300px; /* Limite la largeur maximale */
  flex: 1; /* Assure que les éléments prennent la même largeur */
  min-width: 300px; /* Assure une taille minimale */
}

.contact-item:hover {
  /* Styles pour l'effet de survol */
  transform: translateY(-5px); /* Déplacement vers le haut */
  box-shadow: 0 8px 16px #71c8db; /* Ombre accentuée */
}

.retour-item {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px #71c8db;
  transition: transform 0.3s, box-shadow 0.3s;
  max-width: 260px;
  flex: 1;
  min-width: 10px;
}

.retour-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px #71c8db;
}

.contact-link {
  /* Styles pour les liens de contact */
  text-decoration: none; /* Retire la décoration de lien par défaut */
  display: flex; /* Utilisation de flexbox pour les éléments */
  align-items: center; /* Centre verticalement les éléments */
  gap: 10px; /* Espacement entre l'icône et le texte */
}

.icon-container {
  /* Styles pour le conteneur des icônes */
  display: flex; /* Utilisation de flexbox pour centrer l'icône */
  align-items: center; /* Centre verticalement */
  justify-content: center; /* Centre horizontalement */
  background-color: #71c8db; /* Couleur de fond de l'icône */
  width: 45px; /* Largeur de l'icône */
  height: 45px; /* Hauteur de l'icône */
  border-radius: 50%; /* Forme ronde */
}

.text-primary {
  /* Styles pour le texte principal */
  color: #111418; /* Couleur du texte principal */
  font-size: 1.2em; /* Taille de la police */
}

.text-secondary {
  /* Styles pour le texte secondaire */
  color: #637588; /* Couleur du texte secondaire */
  font-size: 1em; /* Taille de la police */
}

.back-button-container {
  /* Styles pour le conteneur du bouton de retour */
  position: absolute; /* Position absolue par rapport à .contact-page */
  bottom: 20px; /* Positionné à 20px du bas de la page */
  left: 20px; /* Positionné à 20px de la gauche de la page */
}
</style>
